<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" v-if="props.title">
          <h5 class="modal-title">{{ props.title }}</h5>
        </div>
        <div class="modal-body" v-if="props.body">
          <p class="mb-0">{{ props.body }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="runAction">{{ props.buttonActionLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    title: String,
    body: String,
    buttonActionLabel: String,
    buttonAction: Function
})

const runAction = () => {
  props.buttonAction();
}
</script>

<style scoped>
.modal {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
</style>
import Cookies from "js-cookie";
import axios from "../../axios";

export default {
  computed: {
    client () {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('auth_token');

      axios.interceptors.request.use(config => {
        this.$store.dispatch('store/clearErrors');
        return config;
      });

      axios.interceptors.response.use(config => {
        return config;
      }, error => {
        if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
          Cookies.remove('auth_token');
          Cookies.remove('patient');
          Cookies.remove('signer');

          window.location = '/';
          return;
        }

        if (!_.isNil(error.response.data.message)) {
          this.$store.dispatch('store/setErrors', [ error.response.data.message ]);
        }
        return Promise.reject(error);
      });

      return axios;
    }
  }
}
